<template>
  <layout-stepper>
    <template #body>
      <b-link @click="$router.push({name: 'step_options'})" v-if="!has_slug">
        <feather-icon icon="ChevronLeftIcon"></feather-icon>
        Previous Step
      </b-link>
      <div class="d-flex justify-content-center">
        <b-row class="mt-3 col-12 col-lg-8 col-xl-5">
          <b-col class="col-12 text-center">
            <h1 class="mb-1">Any questions you'd like to ask the buyer?</h1>
            <p>Use this to get any info you want to know about the buyer.</p>
            <b-button variant="flat-secondary" class="my-2 p-2" @click="addRequest" :disabled="requests.length > 3">
              <feather-icon icon="PlusIcon"></feather-icon>  
              Add Request
            </b-button>
            <b-pagination
              pills
              v-model="current_page"
              :total-rows="requests.length"
              :per-page="per_page"
              class="justify-content-center"
              v-if="requests.length > 1"
            ></b-pagination>
            <div v-for="(request, index) in requestsFiltered()" :key="index">
              <div class="text-danger text-right cursor-pointer" @click="deleteRequest(request)" v-if="requests.length > 0">Eliminar</div>
              <b-form-group label="Question" label-for="input-description" class="form-group-layout">
                <b-form-textarea id="input-description" v-model="request.question" rows="3"></b-form-textarea>
              </b-form-group>
              <div class="bg-white p-2" v-if="request.type_question === 'radio' || request.type_question === 'checkbox'">
                <b-form-group :label="`Option ${index_options + 1}`" label-for="input-description" v-for="(i, index_options) in request.options" :key="index_options" class="form-group-layout">
                  <div class="d-flex">
                    <b-form-input id="input-description" v-model="request.options[index_options]" rows="3"></b-form-input>
                    <b-button 
                      v-if="index_options !== 0 && index_options !== 1" 
                      class="p-0 p-05 ml-1" 
                      variant="flat-secondary"
                      @click="deleteOption(index_options, current_page - 1)"
                    >
                    <feather-icon icon="TrashIcon" size="18" ></feather-icon></b-button>
                  </div>
                </b-form-group>
                <b-button variant="primary" @click="addOption(current_page - 1)">Add option</b-button>
              </div>
              <b-form-group label="Response type" label-for="input-delivery" class="mt-2 form-group-layout">
                <b-form-select :options="$t('creator.request_type')" id="input-delivery" v-model="request.type_question"></b-form-select>
              </b-form-group>
              <div class="d-flex justify-content-between mt-2">
                <span class="label-checkbox">Is this Question Required?</span>
                <b-form-checkbox v-model="request.required_question" switch>
                </b-form-checkbox>
              </div>
            </div>
            <strong class="text-danger d-block col-12 mt-2 h4" v-if="!validation">{{text_warning}}</strong>
            <b-col class="col-12 d-flex justify-content-center">
              <b-button variant="primary" class="my-3" @click="save()">Save and see preview</b-button>
            </b-col>
          </b-col>
        </b-row>
      </div>
    </template>
  </layout-stepper>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BLink,
  BFormTextarea,
  BFormSelect,
  BPagination,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue';
import layoutStepper from '@/views/components/creator/layoutStepper.vue';
import utils from '@/libs/utils';
import creator_services from '@/services/creator';

export default {
  name: 'requests',
  components: {
    BRow,   
    BCol,
    BButton,
    BLink,
    BFormTextarea,
    layoutStepper,
    BFormSelect,
    BPagination,
    BFormGroup,
    BFormCheckbox,
    BFormInput,
  },
  data() {
    return {
      utils,
      steps: null,
      requests: [],
      options_number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand'
      },
      current_page: 1,
      per_page: 1,
      validation: true,
      text_warning: '',
      proposal: {}
    }
  },
  computed: {
    has_slug() {
      return this.$route.params.slug;
    }
  },
  created() {
    this.getData();
  },
  methods: {
    requestsFiltered () {
      const options = this.requests;
      return options.slice((this.current_page - 1) * this.per_page, this.current_page * this.per_page);
    },
    getData() {
      if (this.has_slug) {
        creator_services.detailProposal(this.$route.params.slug).then((response) => {
          this.requests = response.requests;
          this.proposal = response;
          this.requests.map((request) => {
            request.options = request.options.map((option) => option.title);
          });
        })
      } else {
        this.steps = JSON.parse(localStorage.getItem('steps'));
        if (this.steps.requests) this.requests = this.steps.requests;
      }
    },
    save() {
      this.requests.map((request) => {
        if (request.type_question === 'radio' || request.type_question === 'checkbox') {
          if (request.options.length < 2) {
            this.validation = false;
            this.text_warning = `You have to have at least two options in "${request.question}"`;
          } else {
            this.validation = true;
          }
        } else {
          request.options = [];
          this.validation = true;
        }
      });
      if (this.validation && this.has_slug) {
        this.requests.map((request) => {
          if (request.uuid) {
            creator_services.updateElement(this.proposal.uuid, request.uuid, request, 'request');
          } else {
            creator_services.addOptionOrRequest(this.proposal.uuid, {requests: [request]}, 'request')
          }
        });
        this.$router.push({name: 'step_preview', params: {slug: this.proposal.slug}});
      } else if (this.validation) {   
        this.steps['requests'] = this.requests;
        localStorage.setItem('steps', JSON.stringify(this.steps));
        if (this.requests.length > 0) creator_services.addOptionOrRequest(this.steps.uuid, {requests: this.requests}, 'request');
        this.$router.push({name: 'step_preview', params: {slug: this.steps.slug}});
      }
    },
    addRequest() {
      if (this.requests.length < 4) {
        this.requests.push(
          {
            index: Math.random().toString(36).substr(2, 18),
            question: `Any question`,
            type_question: 'free_text',
            required_question: false,
            options: []
          }
        ) 
      }
    },
    deleteRequest(request) {
      if (this.has_slug) {
        creator_services.deleteElement(this.proposal.uuid, request.uuid, 'request').then(() => {
          this.requests = this.requests.filter((item) => item.uuid !== request.uuid);
        });
      } else {
        this.requests = this.requests.filter((item) => item.index !== request.index);
      }
      this.current_page = this.requests.length === 1 ? this.requests.length : this.requests.length - 1;
    },
    addOption(index_father) {
      this.requests[index_father].options.push(`Option ${this.requests[index_father].options.length + 1}`);
    },
    deleteOption(index_option, index_father) {
      this.requests[index_father].options = this.requests[index_father].options.filter((option, index) => index !== index_option)
    }
  },
}
</script>
<style>
.form-group-layout > label {
  text-align: left;
}
.label-checkbox {
  color: #5e5873;
  font-size: 0.857rem;
}
.p-05 {
  padding: 0.5em !important;
}
</style>